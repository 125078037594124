import React from 'react';
import {Link} from 'gatsby';
import StandardLayout from '../../layouts/standard';
import coverImage from '../../images/about/our-mission/our-mission-cover.jpg';
import teamImage from '../../images/about/our-mission/team.jpg';
import designIcon from '../../images/icons/design.svg';
import performanceIcon from '../../images/icons/performance.svg';
import serviceIcon from '../../images/icons/service.svg';
import communityIcon from '../../images/icons/community.svg';
import sustainabilityIcon from '../../images/icons/sustainability.svg';

export default function OurMission(props) {
    return <StandardLayout title="Our Mission">
        <div>
        <div class="uk-cover-container" data-uk-height-viewport="offset-bottom: 50;">
            <img src={coverImage} alt="" data-uk-cover/>
            <div class="uk-overlay-primary uk-position-cover"></div>
            <div class="uk-overlay uk-position-center-left uk-light">
                <div className="uk-container">
                <h1 className="uk-h1 uk-text-uppercase">
                Our mission
                </h1>
                <div className="uk-width-large">Our mission is to produce and continually develop quality products with highest possible standards.</div>
                </div>
            </div>
        </div>
        </div>
        <div className="uk-container uk-margin-large-top">
               <div className="uk-flex">
               <Link className="uk-button uk-button-primary" style={{margin: "10px"}} to="/about/who-are-we">
                                Who Are We?                 
                            </Link>
                        <Link className="uk-button uk-button-primary" style={{margin: "10px"}} to="/about/infrastructure">
                                Infrastructure                 
                            </Link>
                        <Link className="uk-button uk-button-primary" style={{margin: "10px"}} to="/about/our-core-values">
                                Our Core Values               
                            </Link>
                        <Link className="uk-button uk-button-primary" style={{margin: "10px"}} to="/about/our-leadership">
                                Our Leadership               
                            </Link>
                        <Link className="uk-button uk-button-primary" style={{margin: "10px"}} to="/about/our-responsibility">
                                Our Responsibilities                 
                            </Link>
               </div>
           </div>
        <section className="uk-section">
           <div className="uk-container">
               <div className="">
                   <h2 className="uk-h2 uk-text-uppercase uk-width-xlarge ">
                   we are raising standards of quality and design everyday
                   </h2>
                   <div className="uk-width-large ">
                   We always strive to service our customers with the utmost integrity and to their complete satisfaction. We plan to ensure our mission by continuous self improvement coupled with growth in our operations and team while maintaining profitability for our customers, employees and community.
                   </div>
               </div>
           </div>
        </section>
        <section className="uk-section">
           <div className="uk-container uk-margin-large">
               <div className="uk-text-center">
                   <h2 className="uk-h2 uk-text-uppercase uk-width-xlarge " style={{margin: "auto"}}>
                   What We Do?
                   </h2>
                   <div className="uk-width-large " style={{margin: "auto"}}>
                  
                   </div>
               </div>
                <div class="uk-cover-container uk-margin-medium-top"  style={{height: "60vh"}}>
                    {/* <iframe src="https://www.youtube-nocookie.com/embed/c2pz2mlSfXA?autoplay=1&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;loop=1&amp;modestbranding=1&amp;wmode=transparent" width="100%" height="100%" frameborder="0" allowfullscreen data-uk-cover></iframe> */}
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Se3DQ61ntDo" title="YouTube video player" frameborder="0" allow="accelerometer; controls; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreenn data-uk-cover></iframe>
                </div>
           </div>
        </section>
        <section className="uk-section">
            <div className="uk-container uk-margin-large">
               <div className="uk-text-center">
                   <h2 className="uk-h2 uk-text-uppercase uk-width-xlarge " style={{margin: "auto"}}>
                   OUR TENNETS

                   </h2>
                   {/* <div className="uk-width-large " style={{margin: "auto"}}>
                   Beginning its journey from manufacturing of luxury decorative laminated sheets for both residential as well as industrial applications, Stylam has come a long way in combining industrious efforts and wide experience, to establish itself as an industry leader.
                   </div> */}
               </div>
               <div className="uk-margin-medium-top uk-container-small uk-container">
                   <div className="uk-grid uk-grid-row-medium uk-grid-match uk-flex-center uk-child-width-1-3@s uk-child-1-2" data-uk-grid>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={designIcon} alt=""/>
                                    <div className="uk-text-large  uk-text-uppercase">
                                    Design
                                    </div>
                                    <div className="uk-text-meta">
                                    We offer the widest selection of patterns and surfaces available to match the breadth of your imagination and make your design unique.
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={performanceIcon} alt=""/>
                                    <div className="uk-text-large  uk-text-uppercase">
                                    Performance
                                    </div>
                                    <div className="uk-text-meta">
                                    We use premium materials of high quality standards to keep your design pristine, even in the most strenuous environments.
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={serviceIcon} alt=""/>
                                    <div className="uk-text-large  uk-text-uppercase">
                                    Service
                                    </div>
                                    <div className="uk-text-meta">
                                    We hold a substantial footprint by having Asias largest manufacturing facilities located at Panchkula, India and distributor centres all across the globe to ensure timely delivery.
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={communityIcon} alt=""/>
                                    <div className="uk-text-large  uk-text-uppercase">
                                    Community
                                    </div>
                                    <div className="uk-text-meta">
                                    Stylam is dedicated to giving back to the communities in which we reside by supporting local and national non-profit organizations with both funding and product.
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={sustainabilityIcon} alt=""/>
                                    <div className="uk-text-large  uk-text-uppercase">
                                    Sustainability
                                    </div>
                                    <div className="uk-text-meta">
                                    We are committed to preserving our environment and enhancing the quality of life in the communities we serve.
                                    </div>
                            </div>
                        </div>
                   </div>
               </div>
            </div>
        </section>

        <section className="uk-section">
        <div class="uk-cover-container" style={{minHeight: "80vh", height: "auto"}}>
            <img src={coverImage} alt="" data-uk-cover/>
            <div class="uk-overlay-primary uk-position-cover"></div>
            <div class="uk-overlay uk-position-center">
                <div className="uk-container">
                    <div>
                        <div className="uk-card uk-card-default uk-padding-large">
                            <div className="uk-text-center">
                            <h2 className="uk-h2 uk-text-uppercase">
                            Our Strengths
                            </h2>
                            </div>
                            <div className="uk-margin-medium">
                                <ul class="uk-list uk-list-square uk-column-1-2">
                                   <li>Having global presence in more than 65 Countries.</li>
                                   <li>Over 1200+ Design, 120+ Textures &amp; Finishes.</li>
                                   <li>Having a wide range of High Pressure Laminates, Cubicle board, HPL Exterior Cladding, Acrylic Solid Surfaces.</li>
                                   <li><span>State of the art technology &amp; manufacturing of acrylic solid surfaces.</span></li>
                                   <li>We have multiple surfaces available in High Gloss, Metallic, Anti-bacterial, Chalkboard, FR, Electrostatic, Magnetic, Mirror, Translucent, Chemical- retardant, etc.</li>
                                   <li><span>Having Pan India Channel partner network.</span></li>
                                   <li><span>Credibility of more than 29 years in Industry.</span></li>
                                   <li> Stylam Laminates' uniform and superior sanding ensures best bonding with substrates.</li>
                                   <li><span>Asia’s biggest infrastructure &amp; manufacturing plant of laminates.</span></li>
                                   <li>Largest capacity of hydraulic press in India, having 28 daylight openings in the following sizes- 1220 x 2440 mm, 1300 x 2850 mm, 1300 x 3050 mm, 1860 x 3660 mm &amp; 1860 x 4320 mm.</li>
                                   <li>Largest Laminate producing group in India with a production capacity of over 11 million laminates annually.</li>
                                   <li>First Ever Hot Coating process machine for HPL in India to produce top quality High Gloss &amp; Anti Finger Print laminates in wide ranges.</li>
                                </ul>
                            </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </section>

        <section className="uk-section">
            <div className="uk-container uk-margin-large">
               <div className="uk-text-center">
                   <h2 className="uk-h2 uk-text-uppercase uk-width-xlarge " style={{margin: "auto"}}>
                   GLOBAL PRESENCE
                   </h2>
                   <div className="uk-width-large " style={{margin: "auto"}}>
                   Stylam is all about inventing designs and products of the highest quality. We have stood true to our vision since inception, and today, have grown to become a global entity with a pan India presence and a thriving export market in more than 65 countries. Our major customers are in Europe, The Far East, The Middle East, Russia & North America. Stylam continues to maintain robust YOY export growth.

 
                   </div>
               </div>
               <div className="uk-grid uk-margin-medium uk-grid-row-medium uk-grid-match uk-flex-center uk-child-width-1-4@s uk-child-1-2" data-uk-grid>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={teamImage} className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle" alt=""/>
                                    <div className="uk-text-lead  uk-text-uppercase">
                                    Mr. Jagdish Rai Gupta
                                    </div>
                                    <div className="uk-text-meta">
                                    Managing Director, Chairman
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={teamImage} className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle" alt=""/>
                                    <div className="uk-text-lead  uk-text-uppercase">
                                    Mr. Manit Gupta
                                    </div>
                                    <div className="uk-text-meta">
                                    Director
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={teamImage} className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle" alt=""/>
                                    <div className="uk-text-lead  uk-text-uppercase">
                                    Mr. Satpal Garg
                                    </div>
                                    <div className="uk-text-meta">
                                    Independent Director
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={teamImage} className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle" alt=""/>
                                    <div className="uk-text-lead  uk-text-uppercase">
                                    Ms. Sonia Goyal
                                    </div>
                                    <div className="uk-text-meta">
                                    Independent Director
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={teamImage} className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle" alt=""/>
                                    <div className="uk-text-lead  uk-text-uppercase">
                                    Renu Sood
                                    </div>
                                    <div className="uk-text-meta">
                                    Independent Director
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={teamImage} className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle" alt=""/>
                                    <div className="uk-text-lead  uk-text-uppercase">
                                    Sachin Kumar Bhartiya
                                    </div>
                                    <div className="uk-text-meta">
                                    Nominee Director
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={teamImage} className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle" alt=""/>
                                    <div className="uk-text-lead  uk-text-uppercase">
                                    Mr. Manav Gupta
                                    </div>
                                    <div className="uk-text-meta">
                                    Wholetime Director
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={teamImage} className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle" alt=""/>
                                    <div className="uk-text-lead  uk-text-uppercase">
                                    Purva kansal
                                    </div>
                                    <div className="uk-text-meta">
                                    Independent Director
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={teamImage} className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle" alt=""/>
                                    <div className="uk-text-lead  uk-text-uppercase">
                                    Mr. Sachin Bhatla 
                                    </div>
                                    <div className="uk-text-meta">
                                    Technical Director
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={teamImage} className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle" alt=""/>
                                    <div className="uk-text-lead  uk-text-uppercase">
                                    Vinod Kumar
                                    </div>
                                    <div className="uk-text-meta">
                                    Independent Director
                                    </div>
                            </div>
                        </div>
                </div>
            </div>
            <div className="uk-text-center uk-margin-medium-top">
                   <h2 className="uk-h2 uk-text-uppercase uk-width-xlarge " style={{margin: "auto"}}>
                   Key managerial personnel
                   </h2>
            </div>
               <div className="uk-grid uk-margin-medium uk-grid-row-medium uk-grid-match uk-flex-center uk-child-width-1-4@s uk-child-1-2" data-uk-grid>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={teamImage} className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle" alt=""/>
                                    <div className="uk-text-lead  uk-text-uppercase">
                                    Mr. Sanjeev Vaid
                                    </div>
                                    <div className="uk-text-meta">
                                    CFO
                                    </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-padding-small uk-text-center">
                                    <img src={teamImage} className="uk-box-shadow-small uk-margin-small-bottom uk-border-circle" alt=""/>
                                    <div className="uk-text-lead  uk-text-uppercase">
                                    Ms. Srishty Chaudhary
                                    </div>
                                    <div className="uk-text-meta">
                                    Company Secretary
                                    </div>
                            </div>
                        </div>
                </div>
        </section>

        <section className="uk-section">
        <div class="uk-cover-container" style={{minHeight: "60vh", height: "auto"}}>
            <img src={coverImage} alt="" data-uk-cover/>
            <div class="uk-overlay-primary uk-position-cover"></div>
            <div class="uk-overlay uk-position-center">
                <div className="uk-container">
                    <div>
                        <div className="uk-card uk-card-default uk-padding-large">
                            <div className="uk-text-center">
                                <h2 className="uk-h2 uk-text-uppercase">
                                Have Queries?
                                </h2>
                                <div className="uk-width-large" style={{margin: "auto"}}> 
                                Connect with us for any query you need resolved and we’ll help you realize your ideas.
                                </div>
                                <Link className="color-primary uk-text-large" to="/contact-us">Contact Us</Link>
                            </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </section>
    </StandardLayout>
}